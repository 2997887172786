@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
	margin: 0;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #4488ff54;
	border-radius: 25px;
	/* margin-top: 50px; */
}
.payant-section-scroll-container > ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #4488ff54;
	border-radius: 25px;
	margin-top: 75px;
}
::-webkit-scrollbar {
	width: 5px;
	border-radius: 25px;
	padding-top: 8px;
	padding-bottom: 8px;
}
::-webkit-scrollbar-thumb {
	border-radius: 25px;
	background-color: #4488ff54;
}
